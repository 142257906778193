import { HttpStatusCode } from '@solidjs/start';

import styles from '~/components/Post.module.scss';
import Title from '~/components/Title';

export default function NotFound() {
  return (
    <main>
      <Title>Página não encontrada!</Title>
      <HttpStatusCode code={404} />
      <article>
        <h1>Página não encontrada!</h1>
        <div class={styles.body}>
          <p>
            Desculpe, mas parece que a página que tentou acessar não existe.
            Provavelmente seguiu um link externo errado ou desatualizado,
            digitou errado uma URL ou ainda tentou acessar uma pasta, página ou
            arquivo restrito.
          </p>
          <p>
            Tente encontrar a página que procura pelo menu principal ou
            verifique se você, por acidente, não tenha digitado o endereço de
            forma errada.
          </p>
        </div>
      </article>
    </main>
  );
}
